import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { css } from "@emotion/core"
import { motion } from "framer-motion"

import * as t from "../../tokens"
import { noop } from "../../utils"
import Button from "../ui/Button"

import introTopFr from "../../assets/images/introTopFr.png"
import introTopEn from "../../assets/images/introTopEn.png"
import introBottom from "../../assets/images/introBottom.png"
import { useLocale } from "../LocaleProvider"
import useViewport from "../../hooks/useViewport"

const imgStyles = css`
  display: none;
  width: 100vw;

  @media ${t.mediaQueries.mobile} {
    display: block;
  }
`

const wrapperStyles = height => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;

  padding: 20px;

  @media ${t.mediaQueries.mobile} {
    justify-content: space-between;
    min-height: ${height}px;
    padding: 0 10px;
  }
`

const blockStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const paragraphStyles = css`
  width: 100%;
  max-width: 500px;
  margin-bottom: 1rem;
  font-size: ${t.fontSize.big};
  text-align: center;

  @media ${t.mediaQueries.mobile} {
    margin-bottom: 1em;
    font-size: ${t.fontSize.baseTablet};
  }
`

const Intro = ({ onNext = noop, isVivatech = false }) => {
  const intl = useIntl()
  const [locale] = useLocale()
  const vivaTechText =
    "Faites le plein d'idées vidéos pour votre stratégie de contenu et tentez de remporter une GoPro Hero 10 Black !<br><strong>Vous êtes à 3 questions du jackpot !</strong>"

  const viewport = useViewport()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <motion.div exit={{ opacity: 0, transition: { duration: 0.2 } }}>
      <div css={wrapperStyles(viewport.height)}>
        <img src={locale === "fr" ? introTopFr : introTopEn} css={imgStyles} />

        <div
          css={blockStyles}
          style={{
            marginTop: `${isVivatech && locale === "fr" ? "-20px" : "0"}`,
          }}
        >
          <p
            css={paragraphStyles}
            dangerouslySetInnerHTML={{
              __html:
                isVivatech && locale === "fr"
                  ? vivaTechText
                  : intl.formatMessage({ id: "intro.text" }),
            }}
          />
          <Button red onClick={onNext}>
            <FormattedMessage id="intro.cta" />
          </Button>
        </div>
        <img src={introBottom} css={imgStyles} />
      </div>
    </motion.div>
  )
}

Intro.propTypes = {
  onNext: PropTypes.func,
  isVivatech: PropTypes.bool,
}

export default Intro
