import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { extractTemplateId } from "../utils"
import DeWordings from "../intl/de.json"

const query = graphql`
  query GetAirtableTranslations {
    templates: allAirtable(filter: { table: { eq: "templates" } }) {
      edges {
        node {
          data {
            name
            name_fr
            url
          }
        }
      }
    }

    translations: allAirtable(filter: { table: { eq: "translations" } }) {
      edges {
        node {
          data {
            message
            message_fr
            id
          }
        }
      }
    }

    networks: allAirtable(filter: { table: { eq: "networks" } }) {
      edges {
        node {
          data {
            slug
            name
            name_fr
          }
        }
      }
    }

    projects: allAirtable(filter: { table: { eq: "projects" } }) {
      edges {
        node {
          data {
            slug
            name
            name_fr
          }
        }
      }
    }

    roles: allAirtable(filter: { table: { eq: "roles" } }) {
      edges {
        node {
          data {
            slug
            name
            name_fr
          }
        }
      }
    }
  }
`

const buildBaseTranslations = (data, lang) => {
  const key = lang === "fr" ? "message_fr" : "message"
  const entries = data.edges.map(e => [e.node.data.id, e.node.data[key]])

  return Object.fromEntries(entries)
}

const buildChoiceTranlations = (data, prefix, lang) => {
  const key = lang === "fr" ? "name_fr" : "name"
  const entries = data.edges.map(e => [
    `${prefix}.choices.${e.node.data.slug}`,
    e.node.data[key],
  ])

  return Object.fromEntries(entries)
}

const buildTemplateTranslations = (data, lang) => {
  const key = lang === "fr" ? "name_fr" : "name"
  const uniqueTemplates = data.edges
    .filter(e => e.node.data.url !== null)
    .reduce((r, e) => {
      const hasOne = r.some(
        x =>
          extractTemplateId(x.node.data.url) ===
          extractTemplateId(e.node.data.url)
      )
      return hasOne ? r : [...r, e]
    }, [])
  const entries = uniqueTemplates.map(t => [
    `templates.${extractTemplateId(t.node.data.url)}.title`,
    t.node.data[key],
  ])

  return Object.fromEntries(entries)
}

const useAirtableTranslations = () => {
  const data = useStaticQuery(query)
  const result = useMemo(() => {
    return {
      en: {
        ...buildBaseTranslations(data.translations, "en"),
        ...buildChoiceTranlations(data.networks, "networks", "en"),
        ...buildChoiceTranlations(data.projects, "projects", "en"),
        ...buildChoiceTranlations(data.roles, "roles", "en"),
        ...buildTemplateTranslations(data.templates, "en"),
      },
      fr: {
        ...buildBaseTranslations(data.translations, "fr"),
        ...buildChoiceTranlations(data.networks, "networks", "fr"),
        ...buildChoiceTranlations(data.projects, "projects", "fr"),
        ...buildChoiceTranlations(data.roles, "roles", "fr"),
        ...buildTemplateTranslations(data.templates, "fr"),
      },
      de: {
        ...DeWordings,
      },
    }
  }, [data])

  return result
}

export default useAirtableTranslations
