import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import { rgba } from "polished"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"

import * as t from "../../tokens"
import Overlay from "../ui/Overlay"
import Button from "../ui/Button"

import VerticalImage from "../../assets/images/vertical.inline.svg"
import HorizontalImage from "../../assets/images/horizontal.inline.svg"
import SquareImage from "../../assets/images/square.inline.svg"
import ArrowLeft from "../../assets/images/arrow_left.inline.svg"
import ArrowRight from "../../assets/images/arrow_right.inline.svg"
import Close from "../../assets/images/close.inline.svg"

const getSiteMetadata = graphql`
  query {
    site {
      siteMetadata {
        tryPlayPlayUrl
      }
    }
  }
`

const wrapperStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 100vw;
  min-height: -webkit-fill-available;
  min-height: 100vh;

  @media ${t.mediaQueries.mobile} {
    justify-content: flex-start;
    min-height: unset;
  }
`

const innerWrapperStyles = css`
  position: relative;

  @media ${t.mediaQueries.mobile} {
    width: 100vw;
    margin: 40px auto 40px;
  }
`

const titleStyles = css`
  margin-bottom: 20px;
  color: ${t.colors.white};
  font-size: ${t.fontSize.big};
  text-align: center;
`

const playerStyles = css`
  display: block;
  width: 560px;
  height: 560px;
  max-height: calc(100vh - 150px);
  margin: 0 auto;
  background: ${t.colors.darkBlue};
  border: 2px solid ${t.colors.lightBlue};
  border-radius: 16px;
  outline: 0;

  @media ${t.mediaQueries.mobile} {
    width: calc(100vw - 20px);
    height: calc(100vw - 20px);
  }
`

const switchesStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0;

  padding-left: 0;

  li {
    margin-right: 20px;
    list-style: none;

    &:first-of-type {
      margin-right: 0;
    }
  }

  @media ${t.mediaQueries.mobile} {
    justify-content: space-between;
    margin-bottom: 20px;

    li:first-of-type {
      margin-right: 20px;
    }

    li:last-of-type {
      margin-right: 0;
    }
  }
`

const controlsWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  width: 560px;
  margin: 20px auto 0;

  @media ${t.mediaQueries.mobile} {
    flex-direction: column;
    width: calc(100% - 20px);
  }
`

const leftButtonStyles = css`
  position: absolute;
  top: 320px;
  left: -100px;

  @media ${t.mediaQueries.mobile} {
    position: static;
  }
`

const rightButtonStyles = css`
  position: absolute;
  top: 320px;
  right: -100px;

  @media ${t.mediaQueries.mobile} {
    position: static;
  }
`

const buttonStyles = isSelected => {
  const color = isSelected ? t.colors.white : t.colors.lightBlue
  const backgroundColor = rgba(t.colors.white, 0.1)
  const cursor = isSelected ? "default" : "pointer"
  const border = isSelected ? `2px solid ${color}` : "none"

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return css`
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50px;
    height: 50px;
    color: ${color};
    background-color: ${backgroundColor};
    border: ${border};
    border-radius: 4px;
    outline: 0;
    cursor: ${cursor};

    @media ${t.mediaQueries.mobile} {
      width: 40px;
      height: 40px;
    }
  `
}

const closeButtonStyles = css`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;

  @media ${t.mediaQueries.mobile} {
    top: 10px;
    right: 10px;
  }
`

const clickAreaStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`

const PlayVideo = ({
  videoData,
  preferredFormat,
  onNext,
  onPrevious,
  onClose,
}) => {
  const data = useStaticQuery(getSiteMetadata)
  const [format, setFormat] = useState(preferredFormat)

  useEffect(() => {
    const handleKey = e => {
      if (e.key === "Escape" || e.key === "Esc") onClose()
      if (e.key === "Left" || e.key === "ArrowLeft") onPrevious()
      if (e.key === "Right" || e.key === "ArrowRight") onNext()
    }

    window.addEventListener("keydown", handleKey)
    return () => {
      window.removeEventListener("keydown", handleKey)
    }
  }, [onClose, onNext, onPrevious])

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        css={wrapperStyles}
      >
        <button
          css={[buttonStyles(false), closeButtonStyles]}
          onClick={onClose}
        >
          <Close />
        </button>
        <div css={innerWrapperStyles}>
          <h3 css={titleStyles}>
            <FormattedMessage id={`templates.${videoData.id}.title`} />
          </h3>
          <video
            autoPlay
            playsInline
            controls
            css={playerStyles}
            src={videoData.urls[format]}
          />
          <div css={controlsWrapperStyles}>
            <ul css={switchesStyles}>
              <li>
                <button
                  css={[buttonStyles(false), leftButtonStyles]}
                  onClick={onPrevious}
                >
                  <ArrowLeft />
                </button>
              </li>
              <li>
                <button
                  css={buttonStyles(format === "horizontal")}
                  onClick={() => setFormat("horizontal")}
                >
                  <HorizontalImage />
                </button>
              </li>
              <li>
                <button
                  css={buttonStyles(format === "vertical")}
                  onClick={() => setFormat("vertical")}
                >
                  <VerticalImage />
                </button>
              </li>
              <li>
                <button
                  css={buttonStyles(format === "square")}
                  onClick={() => setFormat("square")}
                >
                  <SquareImage />
                </button>
              </li>
              <li>
                <button css={[buttonStyles(false), rightButtonStyles]}>
                  <ArrowRight onClick={onNext} />
                </button>
              </li>
            </ul>
            <Button
              anchor
              target="_blank"
              rel="noreferrer"
              href={data.site.siteMetadata.tryPlayPlayUrl}
            >
              <FormattedMessage id="result.test_playplay" />
            </Button>
          </div>
        </div>
        <div css={clickAreaStyles} onClickCapture={onClose} />
      </motion.div>
      <Overlay />
    </>
  )
}

PlayVideo.propTypes = {
  videoData: PropTypes.object,
  preferredFormat: PropTypes.string,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onClose: PropTypes.func,
}

export default PlayVideo
