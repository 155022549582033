import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import useRAF from "../../hooks/useRAF"
import * as t from "../../tokens"
import { noop } from "../../utils"
import VideoSources from "./VideoSources"

const fps = 24.871280216423774
const frameToTime = frame => ((frame - 1) * 1) / fps

const cues = {
  intro: {
    at: frameToTime(1),
    loop: frameToTime(16),
  },
  roles: {
    at: frameToTime(41),
    loop: frameToTime(47),
  },
  networks: {
    at: frameToTime(86),
    loop: frameToTime(91),
  },
  projects: {
    at: frameToTime(130),
    loop: frameToTime(135),
  },
  result: {
    at: frameToTime(280),
  },
}

const VideoPlayer = ({
  locale,
  step,
  onLoad = noop,
  onTransitionStart = noop,
  onTransitionEnd = noop,
  hidden,
}) => {
  const videoRef = useRef()
  const transitionRef = useRef(false)
  const currentStepRef = useRef(null)
  const [isLoaded, setLoaded] = useState(false)

  // Wait for the video source to be loaded
  useEffect(() => {
    const video = videoRef.current

    const onCanPlayThrough = () => {
      if (video.readyState === 4) {
        onLoad()
        setLoaded(true)
        video.removeEventListener("canplaythrough", onCanPlayThrough)
      }
    }

    video.addEventListener("canplaythrough", onCanPlayThrough)

    return () => {
      video.removeEventListener("canplaythrough", onCanPlayThrough)
      setLoaded(false)
    }
  }, [onLoad, locale])

  // React to step changes
  useEffect(() => {
    const video = videoRef.current

    if (isLoaded) {
      // When the video is first loaded sets it's play state to the right one
      if (currentStepRef.current === null) {
        video.currentTime = cues[step].at
        currentStepRef.current = step
      }
      video.play()
    }
  }, [step, isLoaded])

  // When the video is loaded, start a (fanny)RAF to loop the video when needed
  useRAF(() => {
    const video = videoRef.current
    const currentStep = currentStepRef.current
    const isTransition = transitionRef.current

    if (isLoaded) {
      // When we're not transitioning and in a loop, reset the currentTime when looping
      if (currentStep === step && "loop" in cues[step]) {
        if (video.currentTime >= cues[step].loop) {
          video.currentTime = cues[step].at
        }
      }
      // When we're transitioning, keep playing and update our state when we're done
      else if (currentStep !== null && currentStep !== step) {
        if (
          "loop" in cues[currentStep] &&
          video.currentTime >= cues[currentStep].loop &&
          !isTransition
        ) {
          onTransitionStart(step)
          transitionRef.current = true
        } else if (video.currentTime >= cues[step].at) {
          currentStepRef.current = step
          onTransitionEnd(step)
          transitionRef.current = false
        }
      }
    }
  }, [isLoaded, step, onTransitionEnd, onTransitionStart])

  return (
    <>
      <VideoSources hidden={hidden} locale={locale} ref={videoRef} />
    </>
  )
}

VideoPlayer.propTypes = {
  locale: PropTypes.oneOf(t.locales).isRequired,
  step: PropTypes.oneOf(t.steps).isRequired,
  onLoad: PropTypes.func,
  onTransitionStart: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  hidden: PropTypes.bool,
}

export default VideoPlayer
