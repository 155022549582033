import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { rgba } from "polished"

import * as t from "../../tokens"

import SoundOn from "../../assets/images/soundOn.inline.svg"
import SoundOff from "../../assets/images/soundOff.inline.svg"
import { FormattedMessage } from "react-intl"
import { motion, AnimatePresence } from "framer-motion"

const backgroundColor = rgba(t.colors.white, 0.1)
const backgroundColorHover = rgba(t.colors.white, 0.2)
const borderColor = rgba(t.colors.white, 0.3)

const containerStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const buttonStyles = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  color: ${t.colors.washedOutBlue};
  background-color: ${backgroundColor};
  border: 2px solid ${borderColor};
  border-radius: 6px;
  outline: 0;
  cursor: pointer;

  &:hover {
    background-color: ${backgroundColorHover};
  }
`
const bubbleStyles = css`
  position: absolute;
  top: 60px;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-left: 25px;
  padding: 10px 20px;
  color: ${t.colors.washedOutBlue};
  font-weight: 600;
  letter-spacing: 0.01em;
  background-color: #eaeeff;
  border-radius: 1000px;
  box-shadow: 0 1.34018px 5.58408px rgba(57, 74, 144, 0.2219);
`

const variants = {
  initial: {
    opacity: 0,
    y: -100,
  },
  normal: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
  },
}

const MuteButton = ({ showBubble, muted, onChange }) => {
  const handleClick = useCallback(e => () => onChange(e), [onChange])

  return (
    <div css={containerStyles}>
      <AnimatePresence>
        {showBubble && muted && (
          <motion.div
            initial="initial"
            variants={variants}
            animate="normal"
            exit="exit"
            css={bubbleStyles}
          >
            <FormattedMessage id="app.muted" />
          </motion.div>
        )}
      </AnimatePresence>
      <button css={buttonStyles} onClick={handleClick(!muted)}>
        {muted ? <SoundOff /> : <SoundOn />}
      </button>
    </div>
  )
}

MuteButton.propTypes = {
  muted: PropTypes.bool,
  onChange: PropTypes.func,
  showBubble: PropTypes.bool,
}

export default MuteButton
