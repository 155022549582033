export const extractTemplateId = x => new URL(x).searchParams.get("template")

export const noop = () => {}

export const canPlay = mimeTypes => {
  const video = document.createElement("video")
  return mimeTypes.some(i =>
    ["maybe", "probably"].includes(video.canPlayType(i))
  )
}
