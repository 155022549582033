import React, { useEffect } from "react"
import PropTypes from "prop-types"
import LinkedInTag from 'react-linkedin-insight';
import { css } from "@emotion/core"
import { motion } from "framer-motion"
import { FormattedMessage } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"

import * as t from "../../tokens"
import { noop } from "../../utils"
import Heading from "../ui/Heading"
import Field from "../ui/Field"
import Select from "../ui/Select"
import Button from "../ui/Button"
import Overlay from "../ui/Overlay"
import { rgba } from "polished"

import Close from "../../assets/images/close.inline.svg"
import fluff1 from "../../assets/images/fluff_1.svg"
import fluff2 from "../../assets/images/fluff_2.svg"
import { useLocale } from "../LocaleProvider"

const wrapperStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 100vw;
  min-height: 100vh;

  @media ${t.mediaQueries.mobile} {
    justify-content: flex-start;
    min-height: -webkit-fill-available;
    min-height: 100vh;
  }
`

const popupStyles = css`
  position: relative;
  display: flex;
  max-width: 1080px;
  margin: 20px;
  overflow-x: hidden;
  background-color: ${t.colors.white};
  border-radius: 8px;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  box-shadow: 0 18px 167px rgba(75, 65, 165, 0.1),
    0 7.51997px 69.7686px rgba(75, 65, 165, 0.0718854),
    0 4.02054px 37.3016px rgba(75, 65, 165, 0.0596107),
    0 2.25388px 20.911px rgba(75, 65, 165, 0.05),
    0 1.19702px 11.1057px rgba(75, 65, 165, 0.0403893),
    0 0.498106px 4.62132px rgba(75, 65, 165, 0.0281146);

  @media ${t.mediaQueries.mobile} {
    flex-direction: column;
  }
`

const leftStyles = css`
  position: relative;
  width: 50%;
  padding: 70px 60px;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  background: linear-gradient(
    222.4deg,
    #e6eaff 16.33%,
    rgba(244, 246, 255, 0) 76.13%
  );

  @media ${t.mediaQueries.tablet} {
    padding: 60px;
  }

  @media ${t.mediaQueries.mobile} {
    width: 100%;
    padding: 30px;
  }
`

const rightStyles = css`
  width: 50%;
  padding: 130px 70px;

  @media ${t.mediaQueries.tablet} {
    padding: 60px 70px;
  }

  @media ${t.mediaQueries.mobile} {
    width: 100%;
    padding: 30px;
  }
`

const jackpotStyles = css`
  margin-top: 40px;
  line-height: 1.6;
`

const fluffStyles = css`
  position: absolute;
`

const fluff1Styles = css`
  bottom: 20px;
  left: 220px;

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const fluff2Styles = css`
  bottom: 60px;
  left: 180px;
  transform: rotate(-30deg) scale(1.1);

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const fluff3Styles = css`
  right: 60px;
  bottom: 100px;

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const fluff4styles = css`
  bottom: 150px;
  left: -30px;
  transform: rotate(-20deg) scale(1.1);

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const closeStyles = css`
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  color: ${t.colors.darkBlue};
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
`

const eventParagraph = css`
  margin-bottom: 0px;
`

const eventDetailParagraph = css`
  margin: 5px;
  font-style: italic;
`

const redShadow = rgba(t.colors.electricBlue, 0.3)

const numberStyles = css`
  color: ${t.colors.electricBlue};
  text-shadow: 5px 5px 0 ${redShadow};
`

const getSiteMetadata = graphql`
  query HubspotSiteMetadataLookup {
    site {
      siteMetadata {
        hubspotPortalId
        hubspotFormId
        linkedinConversionId
      }
    }
  }
`
const readCookie = name => {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

const Subscribe = ({ nVideos, onSubscribe = noop, onClose = noop, isVivatech = false }) => {
  const [locale] = useLocale()
  const metadata = useStaticQuery(getSiteMetadata).site.siteMetadata
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${metadata.hubspotPortalId}/${metadata.hubspotFormId}`
  const onSubmit = e => {
    e.preventDefault()

    const payload = {
      context: {
        hutk: readCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      fields: [
        { name: "firstname", value: e.target.firstname.value },
        { name: "lastname", value: e.target.lastname.value },
        { name: "email", value: e.target.email.value },
        { name: "company", value: e.target.company.value },
        { name: "numemployees", value: e.target.numemployees.value },
        { name: "phone", value: locale === "de" ? e.target.phone.value : "" },
        {
          name: "video_idea_machine_results_link",
          value: window.location.href,
        },
        { name: "language", value: locale === "fr" ? "French" : (locale === "de" ? "German" : "English") },
      ],
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then(() => {
      const linkedinConversionId =
        locale === "de" ? "9556057" : metadata.linkedinConversionId
      LinkedInTag.track(linkedinConversionId)
      onSubscribe()
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <motion.div
        key="popup"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        css={wrapperStyles}
      >
        <div css={popupStyles}>
          <div css={leftStyles}>
            <Heading>
              {isVivatech && locale === "fr" ? (
                "Bien joué !"
              ) : (
                <FormattedMessage id="result.popup.jackpot" />
              )}
            </Heading>
            <Heading red css={jackpotStyles}>
              <FormattedMessage
                id="result.popup.title"
                values={{
                  nVideos: nVideos,
                  number: n => <span css={numberStyles}>{n}</span>,
                }}
              />
            </Heading>
            {isVivatech && locale === "fr" && (
              <p css={eventParagraph}>
                Entrez vos coordonnées pour tenter de gagner une GoPro Hero 10 Black !
              </p>
            )}
            {isVivatech && locale === "fr" && (
              <p css={eventDetailParagraph}>
                * une seule entrée par participant sera comptabilisée
              </p>
            )}
            <div>
              <img src={fluff2} css={[fluffStyles, fluff1Styles]} />
              <img src={fluff1} css={[fluffStyles, fluff2Styles]} />
              <img src={fluff1} css={[fluffStyles, fluff3Styles]} />
              <img src={fluff2} css={[fluffStyles, fluff4styles]} />
            </div>
          </div>
          <div css={rightStyles}>
            <button onClick={onClose} css={closeStyles}>
              <Close />
            </button>
            <form onSubmit={onSubmit} method="post" action={url}>
              <Field name="firstname" required />
              <Field name="lastname" required />
              <Field name="company" required />
              <Select name="numemployees" required>
                <option value="1-100">1 &ndash; 100</option>
                <option value="100 - 250">100 &ndash; 250</option>
                <option value="250 - 500">250 &ndash; 500</option>
                <option value="500-1000">500 &ndash; 1000</option>
                <option value="1000-5000">1000 &ndash; 5000</option>
                <option value="5000 - 10000">5000 &ndash; 10000</option>
                <option value="10000 +">10000 +</option>
              </Select>
              <Field type="email" name="email" required />
              {locale === "de" && <Field name="phone" required />}
              <Button type="submit">
                <FormattedMessage id="result.popup.cta" />
              </Button>
            </form>
          </div>
        </div>
      </motion.div>
      <Overlay />
    </>
  )
}

Subscribe.propTypes = {
  nVideos: PropTypes.number,
  onSubscribe: PropTypes.func,
  onClose: PropTypes.func,
  isVivatech: PropTypes.bool,
}

export default Subscribe
