import React, { useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import BackButton from "./BackButton"
import LangSelect from "./LangSelect"
import MuteButton from "./MuteButton"

import * as t from "../../tokens"

const containerStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 0;

  @media ${t.mediaQueries.mobile} {
    padding: 10px;
  }
`
const wrapperStyles = showBack => css`
  display: flex;
  align-items: center;
  justify-content: ${showBack ? "space-between" : "flex-end"};
  width: 1180px;
  margin: 0 auto;
`

const rightStyles = css`
  display: flex;
`

const Chrome = ({
  showBack,
  onBack,
  locale,
  onLocaleChange,
  muted,
  onMutedChange,
  showBubble,
}) => {
  const [pushed, setPushed] = useState(false)

  return (
    <div css={containerStyles}>
      <div css={wrapperStyles(showBack)}>
        {showBack && <BackButton onClick={onBack} />}
        <div css={rightStyles}>
          <MuteButton
            muted={muted}
            onChange={onMutedChange}
            showBubble={showBubble && !pushed}
          />
          <LangSelect
            locale={locale}
            onChange={onLocaleChange}
            onHover={setPushed}
          />
        </div>
      </div>
    </div>
  )
}

Chrome.propTypes = {
  showBack: PropTypes.bool,
  locale: PropTypes.string,
  onLocaleChange: PropTypes.func,
  muted: PropTypes.bool,
  onMutedChange: PropTypes.func,
  onBack: PropTypes.func,
  showBubble: PropTypes.bool,
}

export default Chrome
