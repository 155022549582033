import { useCallback, useEffect, useRef } from "react"

const useRAF = (fn, deps) => {
  const ref = useRef()

  const fanny = useCallback(() => {
    fn()
    ref.current = requestAnimationFrame(fanny)
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ref.current = requestAnimationFrame(fanny)
    return () => {
      cancelAnimationFrame(ref.current)
    }
  }, [fanny])
}

export default useRAF
