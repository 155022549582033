import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { rgba } from "polished"

import * as t from "../../tokens"

const headingStyles = isRed => {
  const color = isRed
    ? rgba(t.colors.red, 0.9)
    : rgba(t.colors.saturatedBlue, 0.7)

  const shadowColor = isRed
    ? rgba(t.colors.red, 0.3)
    : rgba(t.colors.electricBlue, 0.3)

  return css`
    max-width: 620px;
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${color};
    font-weight: 900;
    font-size: ${t.fontSize.huge};
    font-family: ${t.fontFamily.agrandir};
    font-style: italic;
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 5px 5px 0 ${shadowColor};
    -webkit-text-stroke: 1px ${t.colors.white};

    @media ${t.mediaQueries.tablet} {
      max-width: 400px;
      font-size: ${t.fontSize.hugeTablet};
    }

    @media ${t.mediaQueries.mobile} {
      font-size: ${t.fontSize.hugeMobile};
    }
  `
}

const Heading = props => {
  const { red, ...otherProps } = props
  return <h1 css={headingStyles(red)} {...otherProps} />
}

Heading.propTypes = {
  red: PropTypes.bool,
}

export default Heading
