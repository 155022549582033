import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { motion } from "framer-motion"

import * as t from "../../tokens"
import madeWithLoveEn from "../../assets/images/madeWithLoveEn.svg"
import madeWithLoveFr from "../../assets/images/madeWithLoveFr.svg"
import { useLocale } from "../LocaleProvider"

const imageStyles = css`
  display: block;
  height: 13px;
`

const wrapperStyles = css`
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 13px;

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const aStyles = css`
  display: block;
  text-decoration: none;
`

const variants = {
  left: {
    x: "calc(50vw - 50% - 20px)",
  },
  center: {
    x: "0px",
  },
}

const MadeWithLove = ({ centered }) => {
  const [locale] = useLocale()

  return (
    <div css={wrapperStyles}>
      <a
        target="_blank"
        rel="noreferrer"
        css={aStyles}
        href="https://www.playplay.com"
      >
        <motion.div variants={variants} animate={centered ? "center" : "left"}>
          <img
            src={locale === "fr" ? madeWithLoveFr : madeWithLoveEn}
            css={imageStyles}
          />
        </motion.div>
      </a>
    </div>
  )
}

MadeWithLove.propTypes = {
  centered: PropTypes.bool,
}

export default MadeWithLove
