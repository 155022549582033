import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import stars from "../../assets/images/stars.svg"
import Step from "./Step"

import * as t from "../../tokens"

const imgStyles = css`
  position: absolute;
  top: -120px;
  left: -60px;
  width: 120px;

  @media ${t.mediaQueries.tablet} {
    top: -80px;
    left: -40px;
    width: 80px;
  }

  @media ${t.mediaQueries.mobile} {
    top: -90px;
    left: -10px;
    width: 80px;
  }
`

const Roles = ({ airtableData, onSubmit }) => {
  return (
    <Step id="roles" airtableData={airtableData} onSubmit={onSubmit}>
      <img css={imgStyles} src={stars} />
    </Step>
  )
}

Roles.propTypes = {
  airtableData: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default Roles
