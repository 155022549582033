import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import React from "react"
import introEn from "../../assets/videos/source/en/IMG_00000.png"
import rolesEn from "../../assets/videos/source/en/IMG_00040.png"
import networksEn from "../../assets/videos/source/en/IMG_00085.png"
import projectsEn from "../../assets/videos/source/en/IMG_00129.png"
import resultEn from "../../assets/videos/source/en/IMG_00300.png"
import introFr from "../../assets/videos/source/fr/IMG_00000.png"
import rolesFr from "../../assets/videos/source/fr/IMG_00040.png"
import networksFr from "../../assets/videos/source/fr/IMG_00085.png"
import projectsFr from "../../assets/videos/source/fr/IMG_00129.png"
import resultFr from "../../assets/videos/source/fr/IMG_00300.png"
import * as t from "../../tokens"
import { css } from "@emotion/core"

const imgStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const imageData = {
  fr: {
    intro: introFr,
    roles: rolesFr,
    networks: networksFr,
    projects: projectsFr,
    result: resultFr,
  },
  en: {
    intro: introEn,
    roles: rolesEn,
    networks: networksEn,
    projects: projectsEn,
    result: resultEn,
  },
  de: {
    intro: introEn,
    roles: rolesEn,
    networks: networksEn,
    projects: projectsEn,
    result: resultEn,
  },
}
const ImageFallback = ({ locale, step }) => (
  <AnimatePresence>
    <motion.img
      css={imgStyles}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={`image-${locale}-${step}`}
      src={imageData[locale][step]}
    />
  </AnimatePresence>
)

ImageFallback.propTypes = {
  locale: PropTypes.oneOf(t.locales).isRequired,
  step: PropTypes.oneOf(t.steps).isRequired,
}

export default ImageFallback
