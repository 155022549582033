import React, { useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import * as t from "../../tokens"
import Button from "./Button"
import { FormattedMessage } from "react-intl"

const cardStyles = css`
  position: relative;
  width: 280px;
  margin: 30px;
  text-align: center;
  background-color: ${t.colors.white};
  border-radius: 8px;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  box-shadow: 0 1.12694px 2.75474px rgba(163, 163, 163, 0.035),
    0 0.598509px 1.46302px rgba(163, 163, 163, 0.0282725),
    0 0.249053px 0.608796px rgba(163, 163, 163, 0.0196802);

  @media ${t.mediaQueries.tablet} {
    margin: 10px;
  }

  @media ${t.mediaQueries.mobile} {
    width: 100%;
  }
`

const videoStyles = css`
  width: 100%;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
`

const titleStyles = css`
  padding: 5px;
  color: ${t.colors.washedOutBlue};
  font-weight: normal;
  font-size: ${t.fontSize.big};
  text-align: center;
`

const buttonStyles = css`
  display: inline-block;
  margin: 0 auto 20px;
`

const VideoCard = ({ videoData, onClick, tryPlayPlayUrl }) => {
  const promiseRef = useRef(null)

  const playInlineVideo = useCallback(e => {
    promiseRef.current = e.target.play()
  }, [])

  const stopPlayback = useCallback(e => {
    e.persist()
    if (promiseRef.current) {
      promiseRef.current.then(() => {
        e.target.pause()
        e.target.currentTime = 0
      })
    }
  }, [])

  return (
    <div css={cardStyles}>
      <video
        playsInline
        loop
        css={videoStyles}
        src={videoData.urls.square}
        onClick={onClick}
        onMouseEnter={playInlineVideo}
        onMouseLeave={stopPlayback}
        poster={videoData.thumbnailUrl}
      ></video>
      <h2 css={titleStyles}>
        <FormattedMessage id={`templates.${videoData.id}.title`} />
      </h2>
      <Button
        anchor
        href={tryPlayPlayUrl}
        secondary
        css={buttonStyles}
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="result.test_playplay" />
      </Button>
    </div>
  )
}

VideoCard.propTypes = {
  videoData: PropTypes.object,
  onClick: PropTypes.func,
  tryPlayPlayUrl: PropTypes.string,
}

export default VideoCard
