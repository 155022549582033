import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"
import { IntlProvider } from "react-intl"
import { useLocation, useNavigate } from "@reach/router"

const context = createContext()
const { Provider } = context

export const useLocale = () => {
  const value = useContext(context)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const setter = locale => {
    if (locale !== value && value === "en") {
      if (locale === "fr") navigate(`/fr${pathname}`)
      if (locale === "de") navigate(`/de${pathname}`)
    } else if (locale !== value && value === "fr") {
      if (locale === "en") navigate(pathname.replace(/^\/fr/, ""))
      if (locale === "de") navigate(pathname.replace(/^\/fr/, "/de"))
    } else if (locale !== value && value === "de") {
      if (locale === "en") navigate(pathname.replace(/^\/de/, ""))
      if (locale === "fr") navigate(pathname.replace(/^\/de/, "/fr"))
    }
  }
  return [value, setter]
}

const LocaleProvider = ({ children, airtableTranslations }) => {
  const { pathname } = useLocation()
  const getLocale = () => {
    if (pathname.startsWith("/fr")) return "fr"
    else if (pathname.startsWith("/de")) return "de"
    else return "en"
  }
  const locale = getLocale()

  return (
    <Provider value={locale}>
      <IntlProvider messages={airtableTranslations[locale]} locale={locale}>
        {children}
      </IntlProvider>
    </Provider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.node,
  airtableTranslations: PropTypes.object.isRequired,
}

export default LocaleProvider
