import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { css } from "@emotion/core"
import { rgba } from "polished"
import { AnimatePresence, motion } from "framer-motion"
import * as t from "../../tokens"
import { noop } from "../../utils"

const listStyles = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 560px;
  margin-top: 2rem;
  padding: 0;

  @media ${t.mediaQueries.tablet} {
    max-width: 50%;
  }

  @media ${t.mediaQueries.mobile} {
    max-width: 100%;
  }
`

const itemStyles = isSelected => {
  const color = isSelected ? t.colors.white : t.colors.washedOutBlue
  const border = isSelected ? t.colors.darkBlue : t.colors.white
  const background = isSelected
    ? rgba(t.colors.darkBlue, 0.8)
    : rgba(t.colors.white, 0.2)

  return css`
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 0;
    padding: 10px 12px;
    color: ${color};
    font-weight: 600;
    list-style: none;
    background-color: ${background};
    border: 1px solid ${border};
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 30ms ease-in;

    @media ${t.mediaQueries.tablet} {
      font-size: ${t.fontSize.baseTablet};
    }

    @media ${t.mediaQueries.mobile} {
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: ${t.fontSize.small}px;
    }
  `
}

const Choices = ({ messagePrefix, data, selected, onChange = noop }) => {
  const has = item => {
    if (selected instanceof Set) {
      return selected.has(item)
    } else {
      return selected === item
    }
  }

  const sortedData = data.sort((a, b) => {
    if (a === "other") {
      return 1
    } else if (b === "other") {
      return -1
    } else {
      return 0
    }
  })

  return (
    <ul css={listStyles}>
      <AnimatePresence>
        {sortedData.map((r, i) => (
          <motion.li
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0, transition: { delay: i * 0.05 } }}
            exit={{ x: "-100%" }}
            key={r}
            css={itemStyles(has(r))}
            onClick={() => onChange(r)}
          >
            <FormattedMessage id={`${messagePrefix}.choices.${r}`} />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  )
}

Choices.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  messagePrefix: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Set)]),
}

export default Choices
