import React, { useEffect, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import LinkedInTag from "react-linkedin-insight"
import { AnimatePresence } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation, useNavigate } from "@reach/router"
import useMediaQuery from "../hooks/useMediaQuery"
import Cookies from "js-cookie"

import SlotMachine from "./slot_machine/SlotMachine"
import MadeWithLove from "./ui/MadeWithLove"
import Intro from "./scenes/Intro"
import Roles from "./scenes/Roles"
import Networks from "./scenes/Networks"
import Projects from "./scenes/Projects"
import Grid from "./scenes/Grid"
import PlayVideo from "./scenes/PlayVideo"
import Subscribe from "./scenes/Subscribe"

import { getTemplates, guessPreferredFormat } from "../templates"
import { parseRoute, routeTo } from "../routes"
import Viewport from "./ui/Viewport"

import * as t from "../tokens"
import { useLocale } from "./LocaleProvider"
import Chrome from "./ui/Chrome"
import useViewport from "../hooks/useViewport"

const getSiteMetadata = graphql`
  query LinkedInQuery {
    site {
      siteMetadata {
        linkedinPartnerId
      }
    }
  }
`

const guessScene = (route, hasSeenIntro, hasSubscribed) => {
  if (route === null) return "notFound"
  else if (route === "roles" && !hasSeenIntro) return "intro"
  else if (route === "result" && !hasSubscribed) return "subscribe"
  else if (route === "result" && hasSubscribed) return "grid"
  else return route
}

const App = ({ airtableData }) => {
  const transitionStartTimeout = useRef()
  const transitionEndTimeout = useRef()

  const metadata = useStaticQuery(getSiteMetadata).site.siteMetadata

  const [locale, setLocale] = useLocale()

  const viewport = useViewport()

  const location = useLocation()
  const navigate = useNavigate()
  const [hasSeenIntro, setHasSeenIntro] = useState(false)
  const [hasSubscribed, setHasSubscribed] = useState(false)

  const route = parseRoute(location.pathname, airtableData)
  const scene = guessScene(route.name, hasSeenIntro, hasSubscribed)

  const [uiScene, setUiScene] = useState(scene)
  const [animationScene, setAnimationScene] = useState(scene)

  const preferredFormat = guessPreferredFormat(airtableData, route.data)
  const templates = getTemplates(airtableData, route.data)

  const [currentVideoIndex, setCurrentVideoIndex] = useState(null)

  const [isMuted, setMuted] = useState(true)

  // Manage specific wording when Vivatech UTM is present
  const [isVivatech, setVivatech] = useState(false)
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const campaign = urlParams.get("utm_campaign")
    if (campaign === "fr_event_vivatech_06_22") {
      !Cookies.get("is_vivatech") && Cookies.set("is_vivatech", "true")
    }
    Cookies.get("is_vivatech") === "true" && setVivatech(true)
  }, [isVivatech])

  const isMobile = useMediaQuery(t.mediaQueries.mobile)
  const showMachine =
    !isMobile &&
    ["intro", "roles", "networks", "projects", "result"].includes(
      animationScene
    )
  const isOverlayOpen = uiScene === "subscribe" || currentVideoIndex !== null

  const showChrome = [
    "intro",
    "transitionToRoles",
    "roles",
    "networks",
    "projects",
  ].includes(uiScene)

  const showBack = ["networks", "projects"].includes(uiScene)

  useEffect(() => {
    if (
      scene !== uiScene &&
      scene !== animationScene &&
      !transitionEndTimeout.current &&
      !transitionStartTimeout.current
    ) {
      setAnimationScene(scene)
      setUiScene(scene)
    }
  }, [location, scene, uiScene, animationScene])

  const transitionStart = useCallback(step => {
    transitionStartTimeout.current = clearTimeout(
      transitionStartTimeout.current
    )
    if (["networks", "projects"].includes(step)) {
      setUiScene(step)
    } else if (step === "result") {
      setUiScene("waitingForGrid")
    }
  }, [])

  useEffect(() => {
    LinkedInTag.init(metadata.linkedinPartnerId)
  }, [])

  const transitionEnd = useCallback(
    step => {
      transitionEndTimeout.current = clearTimeout(transitionEndTimeout.current)
      if (step === "roles") {
        setUiScene("roles")
      } else if (step === "result") {
        if (hasSubscribed) {
          setUiScene("grid")
          setAnimationScene("grid")
        } else {
          setUiScene("subscribe")
          setAnimationScene("subscribe")
        }
      }
    },
    [hasSubscribed]
  )

  const nextToRoles = useCallback(() => {
    setHasSeenIntro(true)

    if (isMobile) {
      setUiScene("roles")
    } else {
      setUiScene("transitionToRoles")
      setAnimationScene("roles")
      transitionStartTimeout.current = setTimeout(() => {
        transitionStart("roles")
      }, 1000)
      transitionEndTimeout.current = setTimeout(() => {
        transitionEnd("roles")
      }, 1000)
    }
  }, [isMobile, transitionStart, transitionEnd])

  const nextToNetworks = useCallback(
    role => {
      navigate(
        routeTo(locale, { name: "networks", data: { ...route.data, role } })
      )

      if (isMobile) {
        setUiScene("networks")
      } else {
        setAnimationScene("networks")
        transitionStartTimeout.current = setTimeout(() => {
          transitionStart("networks")
        }, 1000)
        transitionEndTimeout.current = setTimeout(() => {
          transitionEnd("networks")
        }, 1000)
      }
    },
    [route, locale, isMobile, transitionStart, transitionEnd, navigate]
  )

  const nextToProjects = useCallback(
    networks => {
      navigate(
        routeTo(locale, {
          name: "projects",
          data: { ...route.data, networks },
        })
      )

      if (isMobile) {
        setUiScene("projects")
      } else {
        setAnimationScene("projects")
        transitionStartTimeout.current = setTimeout(() => {
          transitionStart("projects")
        }, 1000)
        transitionEndTimeout.current = setTimeout(() => {
          transitionEnd("projects")
        }, 1000)
      }
    },
    [route, locale, isMobile, transitionStart, transitionEnd, navigate]
  )

  const nextToResult = useCallback(
    projects => {
      navigate(
        routeTo(locale, { name: "result", data: { ...route.data, projects } })
      )

      if (isMobile) {
        if (hasSubscribed) {
          setUiScene("grid")
        } else {
          setUiScene("subscribe")
        }
      } else {
        setAnimationScene("result")
        transitionStartTimeout.current = setTimeout(() => {
          transitionStart("result")
        }, 1000)
        transitionEndTimeout.current = setTimeout(() => {
          transitionEnd("result")
        }, 6000)
      }
    },
    [
      route,
      locale,
      isMobile,
      hasSubscribed,
      transitionStart,
      transitionEnd,
      navigate,
    ]
  )

  const closeSubscribe = useCallback(() => {
    const { role, networks } = route.data
    navigate(routeTo(locale, { name: "projects", data: { role, networks } }), {
      replace: true,
    })
    setUiScene("projects")
    setAnimationScene("projects")
  }, [locale, route, navigate])

  const nextToGrid = useCallback(() => {
    setHasSubscribed(true)
    setUiScene("grid")
  }, [])

  const openPlayer = useCallback(i => {
    setCurrentVideoIndex(i)
  }, [])

  const closePlayer = useCallback(() => {
    setCurrentVideoIndex(null)
  }, [])

  const nextVideo = useCallback(() => {
    setCurrentVideoIndex(current => {
      if (current === templates.length - 1) {
        return 0
      } else {
        return current + 1
      }
    })
  }, [templates])

  const previousVideo = useCallback(() => {
    setCurrentVideoIndex(current => {
      if (current === 0) {
        return templates.length - 1
      } else {
        return current - 1
      }
    })
  }, [templates])

  const tryAgain = useCallback(() => {
    navigate(routeTo(locale, { name: "roles" }))
    setUiScene("roles")
    setAnimationScene("roles")
  }, [locale, navigate])

  const goBack = useCallback(() => {
    if (route.name === "networks") {
      navigate(routeTo(locale, { name: "roles" }), { replace: true })
      setUiScene("roles")
      setAnimationScene("roles")
    } else if (route.name === "projects") {
      const { role } = route.data
      navigate(routeTo(locale, { name: "networks", data: { role } }), {
        replace: true,
      })
      setUiScene("networks")
      setAnimationScene("networks")
    }
  }, [locale, route, navigate])

  useEffect(() => {
    if (!route.name) {
      navigate(routeTo(locale, { name: "roles" }))
      setUiScene("intro")
      setAnimationScene("intro")
    }
  }, [locale, route, navigate])

  return (
    <>
      <Viewport blockScroll={isOverlayOpen}>
        <AnimatePresence>
          {uiScene === "intro" && <Intro onNext={nextToRoles} isVivatech={isVivatech}></Intro>}
          {uiScene === "roles" && (
            <Roles airtableData={airtableData} onSubmit={nextToNetworks} />
          )}
          {uiScene === "networks" && (
            <Networks airtableData={airtableData} onSubmit={nextToProjects} />
          )}
          {uiScene === "projects" && (
            <Projects airtableData={airtableData} onSubmit={nextToResult} />
          )}
          {uiScene === "subscribe" && (
            <Subscribe
              nVideos={templates.length}
              isVivatech={isVivatech}
              onSubscribe={nextToGrid}
              onClose={closeSubscribe}
            />
          )}
          {currentVideoIndex !== null && (
            <PlayVideo
              preferredFormat={preferredFormat}
              videoData={templates[currentVideoIndex]}
              onClose={closePlayer}
              onNext={nextVideo}
              onPrevious={previousVideo}
            />
          )}
        </AnimatePresence>
        {((uiScene === "subscribe" && !isMobile) || uiScene === "grid") && (
          <Grid
            templates={templates}
            onPlay={openPlayer}
            onTryAgain={tryAgain}
          />
        )}
      </Viewport>
      {showChrome && (
        <Chrome
          showBack={showBack}
          onBack={goBack}
          locale={locale}
          onLocaleChange={setLocale}
          muted={isMuted}
          onMutedChange={setMuted}
          showBubble={uiScene === "intro"}
        />
      )}
      {showChrome && (
        <MadeWithLove
          centered={uiScene !== "intro" && uiScene !== "transitionToRoles"}
        />
      )}
      {showMachine && (
        <SlotMachine
          viewport={viewport}
          scene={animationScene}
          onTransitionStart={transitionStart}
          onTransitionEnd={transitionEnd}
          muted={isMuted}
        />
      )}
    </>
  )
}

App.propTypes = {
  airtableData: PropTypes.object,
}

export default App
