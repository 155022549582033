export const fontFamily = {
  gill: "Gill Sans, sans-serif",
  agrandir: "Agrandir Wide, sans-serif",
}

export const fontSize = {
  base: "16px",
  big: "1.135rem",
  huge: "3.375rem",
  cta: "0.975em",
  small: "0.75em",
  hugeTablet: "2.5rem",
  baseTablet: "0.875rem",
  hugeMobile: "2rem",
}

export const colors = {
  electricBlue: "#2E4AEA",
  darkBlue: "#282699",
  saturatedBlue: "#0500FF",
  washedOutBlue: "#606CB0",
  lightBlue: "#CAD2FF",
  red: "#FF3F56",
  white: "#FFFFFF",
}

export const gradients = {
  linearLightBlue: "linear-gradient(63.18deg, #E5EFFF 0%, #C6D4F9 100%)",
  radialWhite:
    "radial-gradient(96.29% 113.08% at 0% 50.05%, rgba(255, 255, 255, 0.58) 0%, rgba(255, 255, 255, 0) 100%)",
}

export const highResolution = 1.25

export const mediaQueries = {
  tablet: "screen and (max-width: 1024px)",
  mobile: "screen and (max-width: 667px)",
  hiDPI: `(-webkit-min-device-pixel-ratio: ${highResolution}), (min-resolution: 120dpi)`,
}

export const slotMachineSize = {
  width: 2100,
  height: 1131,
}

export const slotMachineScale = {
  large: 0.8,
  tablet: 0.5,
  mobile: 0.3,
}

export const locales = ["en", "fr", "de"]

export const steps = ["intro", "roles", "networks", "projects", "result"]
