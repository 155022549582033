const routes = {
  roles: () => "",
  networks: ({ role }) => `${role}`,
  projects: ({ role, networks }) =>
    `${role}/${networks.slice().sort().join("+")}`,
  result: ({ role, networks, projects }) =>
    `${role}/${networks
      .slice()
      .sort()
      .join("+")}/${projects.slice().sort().join("+")}`,
}

export const parseRoute = (pathname, airtableData) => {
  const { roles, networks, projects } = airtableData

  let data = {}
  let name = null

  const path = pathname.replace(/^\/fr/, "").replace(/^\/de/, "").replace(/\/$/, "")

  const segments = path.split("/")

  if (path === "") {
    name = "roles"
  } else if (segments[1]) {
    if (!roles.includes(segments[1])) return { route: null }
    name = "networks"
    data.role = segments[1]

    if (segments[2]) {
      const ns = segments[2].split("+")
      if (!ns.every(n => networks.includes(n))) return { route: null }
      name = "projects"
      data.networks = ns

      if (segments[3]) {
        const ps = segments[3].split("+")
        if (!ps.every(p => projects.includes(p))) return { route: null }
        name = "result"
        data.projects = ps
      }
    }
  }

  return { name, data }
}

export const routeTo = (locale, { name, data }) => {
  const pathTo = routes[name](data)

  if (locale === "fr") {
    return `/fr/${pathTo}`
  } else if (locale === "de") {
    return `/de/${pathTo}`
  } else {
    return `/${pathTo}`
  }
}
