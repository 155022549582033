import React from "react"
import App from "./components/App"
import LocaleProvider from "./components/LocaleProvider"
import SEO from "./components/Seo"
import Global from "./components/ui/Global"
import useAirtableData from "./hooks/useAirtableData"
import useAirtableTranslations from "./hooks/useAirtableTranslations"

import "intl-pluralrules"

const IndexPage = () => {
  const airtableData = useAirtableData()
  const airtableTranslations = useAirtableTranslations()

  return (
    <>
      <Global />
      <LocaleProvider airtableTranslations={airtableTranslations}>
        <SEO />
        {typeof window !== "undefined" && <App airtableData={airtableData} />}
      </LocaleProvider>
    </>
  )
}

export default IndexPage
