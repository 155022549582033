import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import * as t from "../../tokens"
import { rgba } from "polished"

const buttonStyles = (isSecondary, isRed) => {
  const color = isSecondary ? t.colors.electricBlue : t.colors.white
  const backgroundColor = isSecondary
    ? rgba(t.colors.lightBlue, 0.8)
    : isRed
    ? t.colors.red
    : t.colors.electricBlue
  const borderColor = isSecondary
    ? t.colors.lightBlue
    : isRed
    ? t.colors.red
    : t.colors.electricBlue

  return css`
    display: inline-block;
    padding: 16px 40px 12px;
    color: ${color};
    font-weight: 900;
    font-size: ${t.fontSize.cta};
    font-family: ${t.fontFamily.agrandir};
    font-style: italic;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: ${backgroundColor};
    border: 1px solid ${borderColor};
    border-radius: 4px;
    outline: 0;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      transition: transform 50ms ease-in-out;
    }

    @media ${t.mediaQueries.mobile} {
      padding: 12px 30px 9px;
    }
  `
}

const Button = props => {
  const { secondary, red, anchor, ...otherProps } = props
  if (anchor) {
    return <a css={buttonStyles(secondary, red)} {...otherProps} />
  } else {
    return <button css={buttonStyles(secondary, red)} {...otherProps} />
  }
}

Button.propTypes = {
  secondary: PropTypes.bool,
  anchor: PropTypes.bool,
  red: PropTypes.bool,
}

export default Button
