import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import * as t from "../../tokens"
import { FormattedMessage, useIntl } from "react-intl"

const fieldStyles = css`
  margin-bottom: 50px;
`

const labelStyles = css`
  display: block;
  color: ${t.colors.washedOutBlue};
  font-weight: 600;
  font-size: ${t.fontSize.small};
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

const inputStyles = css`
  width: 100%;
  padding: 5px 0;
  color: ${t.colors.darkBlue};
  font-size: ${t.fontSize.base};
  font-family: ${t.fontFamily.gill};
  border: 0;
  border-bottom: 1px solid ${t.colors.washedOutBlue};
  outline: 0;

  &::placeholder {
    color: ${t.colors.washedOutBlue};
  }

  &:focus {
    border-bottom-color: ${t.colors.darkBlue};
  }
`

const Field = props => {
  const intl = useIntl()
  const { type, name, ...otherProps } = props

  return (
    <div css={fieldStyles}>
      <label css={labelStyles} htmlFor={name}>
        <FormattedMessage id={`result.popup.form.${name}`} />
      </label>
      <input
        css={inputStyles}
        type={type}
        name={name}
        {...otherProps}
        placeholder={intl.formatMessage({
          id: `result.popup.form.${name}_placeholder`,
        })}
      />
    </div>
  )
}

Field.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
}

export default Field
