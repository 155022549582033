import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import buttonAudio from "../../assets/sounds/Bouton.mp3"
import leverAudio from "../../assets/sounds/Levier.mp3"
import coinsAudio from "../../assets/sounds/Pieces.mp3"
import loopAudio from "../../assets/sounds/Take Me Down To The Fashion Show - NoMBe.mp3"

const SoundPlayer = ({ step, muted }) => {
  const buttonRef = useRef()
  const leverRef = useRef()
  const coinRef = useRef()
  const loopRef = useRef()

  useEffect(() => {
    buttonRef.current.volume = 0.2
    leverRef.current.volume = 0.2
    coinRef.current.volume = 0.2
    loopRef.current.volume = 0.1
  }, [])

  useEffect(() => {
    if (!muted) {
      if (step === "roles") {
        leverRef.current.currentTime = 0
        leverRef.current.play()
      } else if (
        step === "networks" ||
        step === "projects" ||
        step === "result"
      ) {
        buttonRef.current.currentTime = 0
        buttonRef.current.play()
      } else if (step === "coins") {
        coinRef.current.currentTime = 0
        coinRef.current.play()
      }
    }
  }, [step, muted])

  useEffect(() => {
    if (muted) {
      if (loopRef.current.paused === false) {
        loopRef.current.pause()
      }
    } else {
      loopRef.current.play()
    }
  }, [muted])

  return (
    <>
      <audio ref={buttonRef} src={buttonAudio} />
      <audio ref={leverRef} src={leverAudio} />
      <audio ref={coinRef} src={coinsAudio} />
      <audio ref={loopRef} src={loopAudio} loop />
    </>
  )
}

SoundPlayer.propTypes = {
  step: PropTypes.string,
  muted: PropTypes.bool,
}

export default SoundPlayer
