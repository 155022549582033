import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { css } from "@emotion/core"
import { motion } from "framer-motion"

import * as t from "../../tokens"

import Heading from "../ui/Heading"
import Choices from "../ui/Choices"
import Button from "../ui/Button"

import star from "../../assets/images/star.svg"

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  max-width: 1180px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 80px 20px;

  @media ${t.mediaQueries.mobile} {
    justify-content: flex-start;
  }
`

const wrapperStyles = css`
  position: relative;

  @media ${t.mediaQueries.mobile} {
    margin-top: 80px;
  }
`

const buttonStyles = showButton => css`
  visibility: ${showButton ? "visible" : "hidden"};
`

const multipleStyles = css`
  color: ${t.colors.washedOutBlue};
  font-size: ${t.fontSize.small};
  letter-spacing: 0.03em;
`

const starStyles = css`
  display: inline-block;
  width: 7px;
  margin-right: 5px;
`

const fluffStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media ${t.mediaQueries.mobile} {
    position: absolute;
  }
`

const Step = ({ airtableData, id, multiple, children, onSubmit }) => {
  const [selected, setSelected] = useState(multiple ? new Set() : undefined)
  const showButton = multiple ? selected.size > 0 : !!selected
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const toggleSelection = item => {
    if (multiple) {
      if (selected.has(item)) {
        selected.delete(item)
        setSelected(new Set(selected))
      } else {
        selected.add(item)
        setSelected(new Set(selected))
      }
    } else {
      setSelected(item)
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div css={containerStyles}>
        <div css={wrapperStyles}>
          <div css={fluffStyles}>{children}</div>
          <motion.div initial={{ x: "-100%" }} animate={{ x: 0 }}>
            <Heading
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: `${id}.title` }),
              }}
            />
          </motion.div>
          {multiple && (
            <p css={multipleStyles}>
              <img css={starStyles} src={star} />
              <FormattedMessage id={`${id}.multiple`} />
            </p>
          )}
          <Choices
            data={airtableData[id]}
            messagePrefix={id}
            selected={selected}
            onChange={toggleSelection}
          />
          <Button
            red
            onClick={() => onSubmit(multiple ? [...selected] : selected)}
            disabled={!showButton}
            css={buttonStyles(showButton)}
          >
            <FormattedMessage id={`${id}.cta`}></FormattedMessage>
          </Button>
        </div>
      </div>
    </motion.div>
  )
}

Step.propTypes = {
  airtableData: PropTypes.object,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
}

export default Step
