import { useEffect, useState } from "react"

const useViewport = () => {
  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    let timeout
    const setViewportOnResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setViewport({ width: window.innerWidth, height: window.innerHeight })
      }, 100)
    }

    window.addEventListener("resize", setViewportOnResize)

    return () => {
      window.removeEventListener("resize", setViewport)
    }
  })

  return viewport
}

export default useViewport
