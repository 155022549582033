import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { rgba } from "polished"

import * as t from "../../tokens"

import arrowLeft from "../../assets/images/arrow_left.svg"

const backgroundColor = rgba(t.colors.white, 0.1)
const backgroundColorHover = rgba(t.colors.white, 0.2)
const borderColor = rgba(t.colors.white, 0.3)

const buttonStyles = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  color: ${t.colors.washedOutBlue};
  background-color: ${backgroundColor};
  border: 2px solid ${borderColor};
  border-radius: 6px;
  outline: 0;
  cursor: pointer;

  &:hover {
    background-color: ${backgroundColorHover};
  }
`

const BackButton = ({ onClick }) => {
  return (
    <div css={buttonStyles} onClick={onClick}>
      <img src={arrowLeft} />
    </div>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func,
}

export default BackButton
