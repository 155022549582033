import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import Step from "./Step"

import insta from "../../assets/images/insta.svg"
import facebook from "../../assets/images/facebook.svg"
import twitter from "../../assets/images/twitter.svg"

import * as t from "../../tokens"

const facebookStyles = css`
  position: absolute;
  top: -100px;
  left: 60px;
  width: 80px;

  @media ${t.mediaQueries.tablet} {
    top: -80px;
    left: 30px;
    width: 60px;
  }

  @media ${t.mediaQueries.mobile} {
    top: -70px;
    left: -5px;
    width: 60px;
  }
`

const instaStyles = css`
  position: absolute;
  top: 10px;
  left: 550px;
  width: 80px;

  @media ${t.mediaQueries.tablet} {
    top: 5px;
    left: 400px;
    width: 60px;
  }

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const twitterStyles = css`
  position: absolute;
  top: 250px;
  left: 300px;
  width: 80px;

  @media ${t.mediaQueries.tablet} {
    top: 300px;
    left: 250px;
    width: 60px;
  }

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const Networks = props => {
  return (
    <Step multiple id="networks" {...props}>
      <img css={instaStyles} src={insta} />
      <img css={facebookStyles} src={facebook} />
      <img css={twitterStyles} src={twitter} />
    </Step>
  )
}

Networks.propTypes = {
  airtableData: PropTypes.object,
}

export default Networks
