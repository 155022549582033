import { useEffect, useMemo, useState } from "react"

const useMediaQuery = query => {
  const matcher = useMemo(() => matchMedia(query), [query])
  const [matches, setMatches] = useState(matcher.matches)

  useEffect(() => {
    if ("addEventListener" in matcher) {
      matcher.addEventListener("change", e => setMatches(e.matches))
    } else {
      matcher.addListener(e => setMatches(e.matches))
    }
  }, [matcher])

  return matches
}

export default useMediaQuery
