import { css } from "@emotion/core"
import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import * as t from "../../tokens"
import { useLocale } from "../LocaleProvider"
import { motion, useAnimation } from "framer-motion"
import VideoPlayer from "./VideoPlayer"
import ImageFallback from "./ImageFallback"
import { canPlay } from "../../utils"
import { mp4MimeType } from "./VideoSources"
import SoundPlayer from "./SoundPlayer"
import { noop } from "../../utils"

const introScale = 1
const stepScale = 1.5

const machineHeightFactor = 0.9
const machineRightFactor = 0.2

const viewportStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100vw;
  height: 100vh;
`

const containerStyles = (isCropped, size) => {
  const width = size.width
  const height = isCropped ? size.height / 2 : size.height

  return css`
    position: absolute;
    left: 0;
    width: ${width}px;
    height: ${height}px;
    margin: 0 auto;
    overflow: hidden;
  `
}

const topStyles = css`
  top: 0;
`

const bottomStyles = css`
  bottom: 0;
`

const compositionStyles = (isLoaded, size) => {
  return css`
    position: absolute;
    width: ${size.width}px;
    height: ${size.height}px;
  `
}

const computeSize = viewport => {
  if (viewport.y > t.slotMachineSize.height) {
    return {
      width: t.slotMachineSize.width,
      height: t.slotMachineSize.height,
    }
  } else {
    const ratio = t.slotMachineSize.width / t.slotMachineSize.height
    return {
      width: viewport.height * machineHeightFactor * ratio,
      height: viewport.height * machineHeightFactor,
    }
  }
}

const topVariants = (viewport, size) => {
  return {
    intro: {
      x: (viewport.width - size.width) / 2,
      y: 0,
      scale: introScale,
    },
    transition: {
      y: viewport.height - size.height,
      x: (viewport.width - size.width) / 2,
      scale: introScale,
    },
  }
}

const bottomVariants = (viewport, size) => {
  return {
    intro: {
      x: (viewport.width - size.width) / 2,
      y: 0,
      scale: introScale,
    },
    step: {
      x:
        (viewport.width - size.width) / 2 + viewport.width * machineRightFactor,
      y: -((viewport.height - size.height) / 2),
      scale: stepScale,
    },
    result: {
      x: (viewport.width - size.width) / 2,
      y: -((viewport.height - size.height) / 2),
      scale: stepScale,
    },
  }
}

const SlotMachine = ({
  scene,
  muted,
  onTransitionStart = noop,
  onTransitionEnd = noop,
  viewport,
}) => {
  const [locale] = useLocale()

  const size = useMemo(() => computeSize(viewport), [viewport])

  const [layout, setLayout] = useState(null)

  const topControls = useAnimation()
  const topVariant = useMemo(() => topVariants(viewport, size), [
    viewport,
    size,
  ])

  const bottomControls = useAnimation()
  const bottomVariant = useMemo(() => bottomVariants(viewport, size), [
    viewport,
    size,
  ])

  const [animationStep, setAnimationStep] = useState(scene)

  const [isLoaded, setLoaded] = useState(false)
  const notifyOnLoad = useCallback(() => {
    setTimeout(() => setLoaded(true), 100)
  }, [])

  const [transition, setTransition] = useState(null)
  const startTransition = useCallback(
    step => {
      setTransition(step)
      onTransitionStart(step)
    },
    [onTransitionStart]
  )

  const stopTransition = useCallback(
    step => {
      setTransition(null)
      onTransitionEnd(step)
    },
    [onTransitionEnd]
  )

  const [soundStep, setSoundStep] = useState()

  useEffect(() => {
    if (layout !== "intro" && scene === "intro") {
      setLayout("intro")
      setAnimationStep("intro")
    } else if (layout === "intro" && scene === "intro") {
      topControls.set(topVariants(viewport, size).intro)
      bottomControls.set(bottomVariants(viewport, size).intro)
    } else if (layout === "intro" && scene === "roles") {
      setLayout("transitionTop")
    } else if (layout === "transitionTop" && scene === "roles") {
      topControls
        .start({
          transition: { type: "tween", duration: 0.3, ease: "backOut" },
          ...topVariant.transition,
        })
        .then(() => {
          setAnimationStep("roles")
          setLayout("transitionBottom")
        })
    } else if (layout === "transitionBottom" && scene === "roles") {
      if (transition) {
        bottomControls
          .start({
            transition: { delay: 0.3, duration: 0.6 },
            ...bottomVariant.step,
          })
          .then(() => {
            setLayout("step")
          })
      }
    } else if (scene === "result" && layout !== "result") {
      setAnimationStep(scene)
      setLayout("result")
    } else if (layout === "result" && scene === "result") {
      bottomControls.start({
        transition: { delay: 0.1, duration: 0.6 },
        ...bottomVariant.result,
      })
    } else if (layout !== "step" && scene !== "intro" && scene !== "result") {
      setAnimationStep(scene)
      setLayout("step")
      bottomControls.set(bottomVariants(viewport, size).step)
    } else if (layout === "step" && scene !== "intro" && scene !== "result") {
      setAnimationStep(scene)
      bottomControls.set(bottomVariants(viewport, size).step)
    }
  }, [
    topControls,
    topVariant,
    bottomControls,
    bottomVariant,
    layout,
    scene,
    viewport,
    size,
    transition,
    onTransitionStart,
  ])

  useEffect(() => {
    if (!muted) {
      if (transition === "roles") {
        setTimeout(() => setSoundStep(transition), 320)
      } else if (transition === "networks" || transition === "projects") {
        setSoundStep(transition)
      } else if (transition === "result") {
        setSoundStep(transition)
        setTimeout(() => setSoundStep("coins"), 1500)
      }
    }
  }, [transition, muted])

  const canPlayVideo = canPlay([mp4MimeType])
  const isFallback = !isLoaded || !canPlayVideo

  useEffect(() => {
    if (isFallback) {
      onTransitionStart(scene)
      onTransitionEnd(scene)
    }
  }, [scene, onTransitionStart, onTransitionEnd, isFallback])

  const isCropped = layout === "intro" || layout === "transitionTop"
  const showTop = layout === "intro" || layout === "transitionTop"

  return (
    <div css={viewportStyles}>
      {showTop && (
        <motion.div
          animate={topControls}
          css={[containerStyles(isCropped, size), topStyles]}
        >
          <div css={[compositionStyles(isLoaded, size), topStyles]}>
            {isFallback && (
              <ImageFallback locale={locale} step={animationStep} />
            )}
            <VideoPlayer
              locale={locale}
              step={animationStep}
              onLoad={notifyOnLoad}
            />
          </div>
        </motion.div>
      )}
      <motion.div
        animate={bottomControls}
        css={[containerStyles(isCropped, size), bottomStyles]}
      >
        <div css={[compositionStyles(isLoaded, size), bottomStyles]}>
          {isFallback && <ImageFallback locale={locale} step={animationStep} />}
          <VideoPlayer
            locale={locale}
            step={animationStep}
            onLoad={notifyOnLoad}
            onTransitionStart={startTransition}
            onTransitionEnd={stopTransition}
            hidden={!isLoaded}
          />
        </div>
      </motion.div>
      {!isFallback && <SoundPlayer step={soundStep} muted={muted} />}
    </div>
  )
}

SlotMachine.propTypes = {
  scene: PropTypes.oneOf(t.steps),
  onLoad: PropTypes.func,
  onTransitionStart: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  muted: PropTypes.bool,
  viewport: PropTypes.object,
}

export default SlotMachine
