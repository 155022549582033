import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

const viewportStyles = isScrollBlocked => {
  const blockScrollStyles = css`
    height: -webkit-fill-available;
    height: 100vh;
    overflow: hidden;
  `
  return css`
    ${isScrollBlocked && blockScrollStyles}
  `
}

const Viewport = ({ blockScroll, children }) => (
  <div css={viewportStyles(blockScroll)}>{children}</div>
)

Viewport.propTypes = {
  blockScroll: PropTypes.bool,
  children: PropTypes.node,
}

export default Viewport
