import { useStaticQuery, graphql } from "gatsby"
import { extractTemplateId } from "../utils"
import { useMemo } from "react"

const query = graphql`
  query GetAirtableData {
    templates: allAirtable(filter: { table: { eq: "templates" } }) {
      edges {
        node {
          data {
            projects {
              data {
                slug
              }
            }
            roles {
              data {
                slug
              }
            }
            format {
              data {
                name
              }
            }
            url
          }
        }
      }
    }

    networks: allAirtable(filter: { table: { eq: "networks" } }) {
      edges {
        node {
          data {
            slug
            format {
              data {
                name
              }
            }
          }
        }
      }
    }

    projects: allAirtable(filter: { table: { eq: "projects" } }) {
      edges {
        node {
          data {
            slug
          }
        }
      }
    }

    roles: allAirtable(filter: { table: { eq: "roles" } }) {
      edges {
        node {
          data {
            slug
          }
        }
      }
    }

    videoURLs: allPlayPlayStoryboardCsv {
      edges {
        node {
          storyboard_id
          rendered_url
          thumbnail_url
          format
        }
      }
    }
  }
`

const extractSlugs = x => x && x.map(d => d.data.slug)

const buildBaseData = data => data.edges.map(e => e.node.data.slug)

const buildFormatData = data => {
  const entries = data.edges.map(e => [
    e.node.data.slug,
    e.node.data.format[0].data.name,
  ])
  return Object.fromEntries(entries)
}

const buildVideoURLs = (id, videoURLData) => {
  const formatURLs = ["square", "vertical", "horizontal"].map(f => {
    const edge = videoURLData.edges.find(
      e => e.node.storyboard_id === id && e.node.format === f
    )

    const value = edge ? edge.node.rendered_url : undefined
    return [f, value]
  })

  return Object.fromEntries(formatURLs)
}

const buildThumbnailURL = (id, videoURLData) => {
  const result = videoURLData.edges.find(
    e => e.node.storyboard_id === id && e.node.format === "square"
  )
  return result ? result.node.thumbnail_url : undefined
}

const buildTemplateData = (templateData, videoURLData) =>
  templateData.edges
    .filter(e => e.node.data.url !== null)
    .filter(e => e.node.data.format[0].data.name === "square")
    .map(e => {
      const nodeData = e.node.data
      const id = extractTemplateId(nodeData.url)

      return {
        id,
        projects: extractSlugs(nodeData.projects),
        roles: extractSlugs(nodeData.roles),
        urls: buildVideoURLs(id, videoURLData),
        thumbnailUrl: buildThumbnailURL(id, videoURLData),
      }
    })

const useAirtableData = () => {
  const data = useStaticQuery(query)

  const result = useMemo(() => {
    return {
      templates: buildTemplateData(data.templates, data.videoURLs),
      networks: buildBaseData(data.networks),
      projects: buildBaseData(data.projects),
      roles: buildBaseData(data.roles),
      formats: buildFormatData(data.networks),
    }
  }, [data])

  return result
}

export default useAirtableData
