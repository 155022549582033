export const guessPreferredFormat = (airtableData, selectionData) => {
  if (!selectionData) return

  const { networks } = selectionData
  const { formats } = airtableData

  if (networks) {
    const counters = networks
      .map(n => formats[n])
      .reduce(
        (r, f) => {
          const result = { ...r }
          result[f]++
          return result
        },
        { vertical: 0, horizontal: 0, square: 0 }
      )

    if (counters.square > 0) {
      return "square"
    } else {
      if (counters.vertical > counters.horizontal) return "vertical"
      else return "horizontal"
    }
  }
}

export const getTemplates = (airtableData, selectionData) => {
  if (!selectionData) return

  const { role, networks, projects } = selectionData
  const { templates } = airtableData

  if (role && networks && projects) {
    const templateSelection = templates.filter(t => {
      const hasRole = t.roles.includes(role)
      const hasProject = t.projects.some(p => projects.includes(p))
      return hasRole && hasProject
    })
    if (templateSelection.length > 0) {
      return templateSelection
    } else {
      return templates.slice(0, 6)
    }
  } else {
    return []
  }
}
