import React from "react"
import { Global as EmotionGlobal, css } from "@emotion/core"

import "normalize.css"
import "sanitize.css"

import "../../assets/fonts/web/agrandir/stylesheet.css"
import "../../assets/fonts/web/gill_sans/stylesheet.css"

import * as t from "../../tokens"

const globalStyles = css`
  html {
    min-height: 100vh;

    @media ${t.mediaQueries.mobile} {
      min-height: unset;
    }
  }

  body {
    min-height: 100vh;
    color: ${t.colors.darkBlue};
    font-size: ${t.fontSize.base};
    font-family: ${t.fontFamily.gill};
    line-height: 1.6;
    background-color: #eaeeff;

    @media ${t.mediaQueries.mobile} {
      min-height: unset;
    }
  }
`

const Global = () => <EmotionGlobal styles={globalStyles} />

export default Global
