import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useRef } from "react"
import videoEnHigh from "../../assets/videos/web/SolidSlotMachineEnHigh.mp4"
import videoEnLow from "../../assets/videos/web/SolidSlotMachineEnLow.mp4"
import videoFrHigh from "../../assets/videos/web/SolidSlotMachineFrHigh.mp4"
import videoFrLow from "../../assets/videos/web/SolidSlotMachineFrLow.mp4"
import * as t from "../../tokens"
import { css } from "@emotion/core"

export const mp4MimeType = "video/mp4"

const videoData = {
  fr: {
    high: videoFrHigh,
    low: videoFrLow,
  },
  en: {
    high: videoEnHigh,
    low: videoEnLow,
  },
  de: {
    high: videoEnHigh,
    low: videoEnLow,
  },
}

const videoStyles = isHidden => css`
  top: 0;
  left: 0;
  display: ${isHidden ? "none" : "block"};
  width: 100%;
  height: 100%;
`
const VideoSources = forwardRef(({ locale, hidden }, ref) => {
  const lastCurrentTimeRef = useRef()

  useEffect(() => {
    const video = ref.current
    const lastCurrentTime = lastCurrentTimeRef.current
    if (lastCurrentTime) {
      video.currentTime = lastCurrentTime
    }
    return () => {
      lastCurrentTimeRef.current = video.currentTime
    }
  }, [ref, locale])

  return (
    <video
      muted
      preload="auto"
      playsInline
      ref={ref}
      key={locale}
      css={videoStyles(hidden)}
    >
      <source
        src={videoData[locale].high}
        type={mp4MimeType}
        media={`${t.mediaQueries.hiDPI}`}
      />
      <source src={videoData[locale].low} type={mp4MimeType} />
    </video>
  )
})

VideoSources.displayName = "VideoSources"
VideoSources.propTypes = {
  locale: PropTypes.oneOf(t.locales).isRequired,
  hidden: PropTypes.bool,
}

export default VideoSources
