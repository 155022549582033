import React, { useEffect } from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import { rgba } from "polished"
import { useIntl, FormattedMessage } from "react-intl"

import * as t from "../../tokens"

import { useLocale } from "../LocaleProvider"
import VideoCard from "../ui/VideoCard"
import Heading from "../ui/Heading"
import Button from "../ui/Button"

import gridTitleEn from "../../assets/images/gridTitleEn.svg"
import gridTitleFr from "../../assets/images/gridTitleFr.svg"

import fluff1 from "../../assets/images/fluff_1.svg"
import fluff2 from "../../assets/images/fluff_2.svg"
import stickerTryEn from "../../assets/images/stickerTryEn.svg"
import stickerTryFr from "../../assets/images/stickerTryFr.svg"
import stickerPlayPlay from "../../assets/images/stickerPlayPlay.svg"
import { useStaticQuery, graphql } from "gatsby"

const wrapperStyles = css`
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`

const titleStyles = css`
  display: block;
  width: 100%;
  height: 160px;
  margin: 60px auto;
`

const gridStyles = css`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
`

const playPlayColor = rgba(t.colors.saturatedBlue, 0.7)
const playPlayShadow = rgba(t.colors.electricBlue, 0.3)

const calloutStyles = css`
  position: relative;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 20px;
  padding: 100px 0;
  text-align: center;

  h1 {
    width: 720px;
    margin: 0 auto;
    line-height: 1.3;
    text-align: center;

    strong {
      color: ${playPlayColor};
      text-shadow: 5px 5px 0 ${playPlayShadow};
    }

    @media ${t.mediaQueries.mobile} {
      width: 100%;
    }
  }
`

const calloutButtonStyles = css`
  margin: 30px auto 0;
`

const actionsStyles = css`
  position: absolute;
  top: 120px;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${t.mediaQueries.mobile} {
    position: fixed;
    top: auto;
    bottom: 20px;
    left: 0;
    z-index: 1;
    width: 100vw;
    padding: 0 10px;
    font-size: ${t.fontSize.small};
  }
`

const buttonStyles = isTest => {
  const background = isTest ? t.colors.darkBlue : t.colors.electricBlue

  return css`
    background-color: ${background};
  `
}

const fluffStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 200px;
  overflow: hidden;
`

const fluff1Styles = css`
  position: absolute;
  top: -30px;
  left: 260px;
  transform: scale(1.6) rotate(-30deg);

  @media ${t.mediaQueries.mobile} {
    left: 30px;
  }
`

const fluff2Styles = css`
  position: absolute;
  top: -20px;
  right: 240px;
  transform: scale(1.6) rotate(15deg);

  @media ${t.mediaQueries.mobile} {
    right: 30px;
  }
`

const stickerPlayPlayStyles = css`
  top: auto;
  bottom: 50px;
  left: 100px;
  width: 150px;

  @media ${t.mediaQueries.mobile} {
    top: -70px;
    right: 20px;
    bottom: auto;
    left: auto;
    width: 100px;
  }
`

const styickerTryStyles = css`
  top: 30px;
  right: 60px;
  left: auto;
  width: 150px;

  @media ${t.mediaQueries.mobile} {
    top: -60px;
    right: auto;
    left: 30px;
    width: 100px;
  }
`

const topStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const getSiteMetadata = graphql`
  query PlayPlayUrlSiteMetadataLookup {
    site {
      siteMetadata {
        tryPlayPlayUrl
      }
    }
  }
`

const Grid = ({ templates, onPlay, onTryAgain }) => {
  const data = useStaticQuery(getSiteMetadata)
  const [locale] = useLocale()
  const intl = useIntl()

  const upperTemplates = templates.slice(0, 6)
  const lowerTemplates = templates.slice(6)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div css={wrapperStyles}>
      <div css={actionsStyles}>
        <Button onClick={onTryAgain} css={buttonStyles(false)}>
          <FormattedMessage id="result.try_again" />
        </Button>
        <Button
          anchor
          target="_blank"
          rel="noreferrer"
          href={data.site.siteMetadata.tryPlayPlayUrl}
          css={buttonStyles(true)}
        >
          <FormattedMessage id="result.test_playplay" />
        </Button>
      </div>
      <div css={topStyles}>
        <img
          css={titleStyles}
          src={locale === "fr" ? gridTitleFr : gridTitleEn}
        />
      </div>
      <div css={fluffStyles}>
        <img src={fluff2} css={fluff1Styles} />
        <img src={fluff1} css={fluff2Styles} />
      </div>
      <div css={gridStyles}>
        {upperTemplates.map((t, i) => {
          return (
            <VideoCard
              key={t.id}
              videoData={t}
              onClick={() => onPlay(i)}
              tryPlayPlayUrl={data.site.siteMetadata.tryPlayPlayUrl}
            />
          )
        })}
      </div>
      <div css={calloutStyles}>
        <Heading
          red
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "result.callout" }),
          }}
        />
        <Button
          anchor
          target="_blank"
          rel="noreferrer"
          href={data.site.siteMetadata.tryPlayPlayUrl}
          css={calloutButtonStyles}
        >
          <FormattedMessage id="result.test_playplay" />
        </Button>
        <img src={stickerPlayPlay} css={[fluffStyles, styickerTryStyles]} />
        <img
          // src={locale === "fr" ? stickerTryFr : stickerTryEn}
          src={stickerPlayPlay}
          css={[fluffStyles, stickerPlayPlayStyles]}
        />
      </div>
      {lowerTemplates.length > 0 && (
        <div css={gridStyles}>
          {lowerTemplates.map((t, i) => {
            return (
              <VideoCard
                key={t.id}
                videoData={t}
                onClick={() => onPlay(i + 6)}
                tryPlayPlayUrl={data.site.siteMetadata.tryPlayPlayUrl}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

Grid.propTypes = {
  templates: PropTypes.array,
  onPlay: PropTypes.func,
  onTryAgain: PropTypes.func,
}

export default Grid
