import React, { useState, useCallback } from "react"
import { css } from "@emotion/core"
import { rgba } from "polished"
import PropTypes from "prop-types"

import * as t from "../../tokens"

import frImage from "../../assets/images/fr@2x.png"
import enImage from "../../assets/images/en@2x.png"
import deImage from "../../assets/images/de@2x.png"

const backgroundColor = rgba(t.colors.white, 0.1)
const backgroundColorHover = rgba(t.colors.white, 0.2)
const borderColor = rgba(t.colors.white, 0.3)

const buttonStyles = (isFirst, isOpen) => {
  const borderBottom = isFirst && isOpen ? `2px solid ${borderColor}` : "none"
  const paddingBottom = isFirst && isOpen ? "14px" : "16px"

  return css`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    height: 50px;
    padding: 16px;
    padding-bottom: ${paddingBottom};
    color: ${t.colors.washedOutBlue};
    background-color: ${backgroundColor};
    border: 0;
    border-bottom: ${borderBottom};
    outline: 0;
    cursor: pointer;

    &:hover {
      background-color: ${backgroundColorHover};
    }

    img {
      height: 12px;
    }

    span {
      margin: 0 10px;
      color: ${t.colors.darkBlue};
      font-weight: 600;

      @media ${t.mediaQueries.mobile} {
        display: none;
      }
    }
  `
}

const selectStyles = css`
  display: flex;
  flex-direction: column;
  height: 50px;
  border: 2px solid ${borderColor};
  border-radius: 6px;
`

const LangSelect = ({ onHover, locale, onChange }) => {
  const [isOpen, setOpen] = useState(false)

  const handleClick = l => e => {
    e.stopPropagation()
    if (!isOpen) {
      setOpen(true)
      onHover(true)
    } else if (locale !== l) {
      onChange(l)
      setOpen(false)
      onHover(false)
    }
  }

  const mouseLeave = useCallback(
    e => {
      e.stopPropagation()
      setOpen(false)
      onHover(false)
    },
    [onHover]
  )

  const language = {
    fr: {
      wording: "Français",
      image: frImage,
    },
    de: {
      wording: "Deutsch",
      image: deImage,
    },
    en: {
      wording: "English",
      image: enImage,
    },
  }

  return (
    <div css={selectStyles} onMouseLeave={mouseLeave}>
      <button
        css={buttonStyles(true, isOpen)}
        onClickCapture={handleClick(locale)}
      >
        <img src={language[locale].image} />
        <span>{language[locale].wording}</span>
      </button>
      {isOpen && (
        <>
          {locale !== "fr" && (
            <button
              css={buttonStyles(false, isOpen)}
              onClickCapture={handleClick("fr")}
            >
              <img src={frImage} />
              <span>Français</span>
            </button>
          )}
          {locale !== "en" && (
            <button
              css={buttonStyles(false, isOpen)}
              onClickCapture={handleClick("en")}
            >
              <img src={enImage} />
              <span>English</span>
            </button>
          )}
          {locale !== "de" && (
            <button
              css={buttonStyles(false, isOpen)}
              onClickCapture={handleClick("de")}
            >
              <img src={deImage} />
              <span>Deutsch</span>
            </button>
          )}
        </>
      )}
    </div>
  )
}

LangSelect.propTypes = {
  locale: PropTypes.string,
  onChange: PropTypes.func,
  onHover: PropTypes.func,
}

export default LangSelect
