import React from "react"
import { css } from "@emotion/core"
import { motion } from "framer-motion"
import { rgba } from "polished"

import * as t from "../../tokens"

const backgroundColor = rgba(t.colors.washedOutBlue, 0.7)

const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: ${backgroundColor};
  backdrop-filter: blur(10px);

  @media ${t.mediaQueries.mobile} {
    height: -webkit-fill-available;
    height: 100vh;
  }
`

const Overlay = props => (
  <>
    <motion.div
      {...props}
      key="overlay"
      exit={{ opacity: 0 }}
      css={overlayStyles}
    />
  </>
)

export default Overlay
