import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import Step from "./Step"

import chat from "../../assets/images/chat.svg"
import chat2 from "../../assets/images/chat2.svg"

import * as t from "../../tokens"
import { useLocale } from "../LocaleProvider"

const chatStyles = css`
  position: absolute;
  top: -40px;
  left: -100px;
  width: 80px;

  @media ${t.mediaQueries.tablet} {
    top: -70px;
    left: -30px;
    width: 70px;
  }

  @media ${t.mediaQueries.mobile} {
    top: -60px;
    left: 0;
    width: 60px;
  }
`

const chat2Styles = isFrench => css`
  position: absolute;
  top: 90px;
  left: ${isFrench ? "400px" : "460px"};
  width: 60px;

  @media ${t.mediaQueries.tablet} {
    top: 30px;
    left: 400px;
    width: 50px;
  }

  @media ${t.mediaQueries.mobile} {
    display: none;
  }
`

const Projects = props => {
  const [locale] = useLocale()

  return (
    <Step multiple id="projects" {...props}>
      <img css={chatStyles} src={chat} />
      <img css={chat2Styles(locale === "fr")} src={chat2} />
    </Step>
  )
}

Projects.propTypes = {
  airtableData: PropTypes.object,
}

export default Projects
